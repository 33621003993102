@import "./global.css";
@tailwind base;
@tailwind components;
@tailwind utilities;


.card-background {
    background: rgba(20, 20, 19, 0.15);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(20, 20, 19, 0.99);
}

.hide-spinner::-webkit-outer-spin-button,
.hide-spinner::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.hide-spinner[type=number] {
    -moz-appearance: textfield;
}